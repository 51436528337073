// extracted by mini-css-extract-plugin
export var relativeWrapper = "News-module--relativeWrapper--1Dis8";
export var decor = "News-module--decor--AoWOF";
export var hamburger = "News-module--hamburger--1fru3";
export var units = "News-module--units--23RMO";
export var reactCalendarMonthViewDays = "News-module--react-calendar__month-view__days--3aX36";
export var reactCalendarTile = "News-module--react-calendar__tile--1WcNk";
export var reactCalendarTileActive = "News-module--react-calendar__tile--active--2KY96";
export var container = "News-module--container--17i2j";
export var newsSources = "News-module--newsSources--3oH0J";
export var logoList = "News-module--logoList--1NZnr";
export var logo = "News-module--logo--3XEoQ";
export var articleQuote = "News-module--articleQuote--jikVY";
export var tag = "News-module--tag--1eSZH";
export var link = "News-module--link--1n5er";