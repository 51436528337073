import React from 'react';
import { useDispatch } from 'react-redux';
import BaseContactForm from '@components/ContactForm';
import * as styles from './ContactForm.module.scss';

const dropdownTypes = {
  TALK: 'let\'s talk',
  TOUR: 'take a tour',
  COWORKING: 'coworking'  
};

const dropdownOptions = [
  dropdownTypes.TALK,
  dropdownTypes.TOUR

];

function ContactForm() {
  const dispatch = useDispatch();
  const submitToNwg = (data) => {
    const formData = new FormData();
    formData.append('formdata_name', `${data.firstname} ${data.lastname}`);
    formData.append('formdata_email', data.email);
    formData.append('formdata_phone', data.phone);
    formData.append('formdata_comments', data.message);

    // UTM source params
    const params = new URL(document.location).searchParams;
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmTerm = params.get('utm_term');

    if (utmSource) {
      formData.append('formdata_source', utmSource);
    }

    if (utmMedium) {
      formData.append('formdata_medium', utmMedium);
    }

    if (utmTerm) {
      formData.append('formdata_keywords', utmTerm);
    }

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://nwgapi.com/apiforms/envy/index.php', true);
    xhr.onload = () => {
      if (xhr.status !== 200) {
        // eslint-disable-next-line no-console
        console.log('Error submitting to nwgapi.com', xhr.statusText);
      }
    };

    // eslint-disable-next-line no-undef
    console.log('netlify env', process.env.NETLIFY);
    xhr.send(formData);
  };

  const handleOptionClick = (e, setFormData) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      option: e.target.dataset.value
    }));

    switch (e.target.dataset.value) {
      case dropdownTypes.TALK:
        return dispatch({ type: 'SET_CONTACT_TALK' });
      case dropdownTypes.TOUR:
        return dispatch({ type: 'SET_CONTACT_TOUR' });
      case dropdownTypes.COWORKING:
        return dispatch({ type: 'SET_CONTACT_COWORKING' });  
    }
  };

  return (
    <div className={styles.relativeWrapper}>
      <BaseContactForm
        heading="CONTACT OLEĂ"
        subHeading="Hey there! Let's talk."
        name="Olea Contact Form"
        options={dropdownOptions}
        handleOptionClick={handleOptionClick}
        styles={styles}
        subject_template="[Olea - {option}] Form Submission from Contact Form"
        id="oleaContact"
        onSubmit={submitToNwg}
      />
    </div>
  );
}

ContactForm.optionTypes = dropdownTypes;

export default ContactForm;
