// extracted by mini-css-extract-plugin
export var relativeWrapper = "TheNeighborhood-module--relativeWrapper--3EvCe";
export var decor = "TheNeighborhood-module--decor--1E-W5";
export var hamburger = "TheNeighborhood-module--hamburger--1twm4";
export var units = "TheNeighborhood-module--units--1FY8H";
export var reactCalendarMonthViewDays = "TheNeighborhood-module--react-calendar__month-view__days--2-4Oq";
export var reactCalendarTile = "TheNeighborhood-module--react-calendar__tile--2nqR5";
export var reactCalendarTileActive = "TheNeighborhood-module--react-calendar__tile--active--31tIS";
export var container = "TheNeighborhood-module--container--3j5zg";
export var carousel = "TheNeighborhood-module--carousel--Ve5tI";
export var cardCarousel = "TheNeighborhood-module--cardCarousel--3zkC9";
export var cardWrapper = "TheNeighborhood-module--cardWrapper--Vvikr";
export var image = "TheNeighborhood-module--image--3HMtQ";
export var infoBlock = "TheNeighborhood-module--infoBlock--Tvu4c";
export var arrow = "TheNeighborhood-module--arrow--273y1";
export var backButton = "TheNeighborhood-module--backButton--2C0fP";
export var nextButton = "TheNeighborhood-module--nextButton--2SN3W";
export var infoCarousel = "TheNeighborhood-module--infoCarousel--28i-M";
export var infoWrapper = "TheNeighborhood-module--infoWrapper--3GRrG";
export var info = "TheNeighborhood-module--info--3imlo";
export var name = "TheNeighborhood-module--name--3OXMy";
export var description = "TheNeighborhood-module--description--36TcV";
export var address = "TheNeighborhood-module--address--3fr7_";
export var divider = "TheNeighborhood-module--divider--2oTi0";
export var directions = "TheNeighborhood-module--directions--3d_tE";