import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import { BackButton, NextButton } from '@olea/ArrowButtons';
import * as styles from './Amenities.module.scss';

const tabList = [
  'CAFÉ',
  'COWORKING',
  'BIERGARTEN',
  'PARKING',
];

const carouselContent = [
  {
    title: 'Kanela',
    content: (
      <p>Residents and their guests will feel gratified in our elegant cafe. Coffee and excellent cuisine are all served with sophistication and flare.<br/><br/><a href="https://maps.app.goo.gl/RLqkgBeRkYSuo1jS8" target="_blank" rel="noreferrer">270 Orange St, Newark, NJ 07103</a></p>
    ),
    image: (
      <StaticImage
        src="../../../images/olea/Amenities/cafe_olea.jpeg"
        className={styles.image}
        alt="yellow drink"
      />
    ),
  },
  {
    title: 'Coworking at Envy',
    content: (
      <p>Our thoughtful coworking spaces are designed with the aesthetics of work-from-home convenience and office productivity.<br/><br/><a href="https://maps.app.goo.gl/uorsRSaGGnhjagdi6" target="_blank" rel="noreferrer">58-84 Norfolk St Suite 1, Newark, NJ 07103</a></p>
    ),
    image: (
      <StaticImage
        src="../../../images/olea/Amenities/coworking.jpeg"
        className={styles.image}
        alt="coworking room"
        formats={["auto"]}
        quality={100}
      />
    ),
  },
  {
    title: 'Envy Bar & Grille',
    content: (
      <p>Dine like Dionsyius in our beer garden. Taps are flowing with local brews and international favorites. Whether you toast with friends or sip in solitude, libations here are the talk of the town.<br/><br/><a href="https://maps.app.goo.gl/xfa2JUvQkYMw6GPE8" target="_blank" rel="noreferrer">51 Newark Street, Newark, NJ 07103</a></p>
    ),
    image: (
      <StaticImage
        src="../../../images/olea/Amenities/biergarten_olea.jpeg"
        className={styles.image}
        alt="people drinking beer"
      />
    ),
  },
  {
    title: 'Parking',
    content: (
      <p>- Indoor Parking Spaces<br/>- Electric Vehicle Charging Capabilities</p>
    ),
    image: (
      <StaticImage
        src="../../../images/olea/Amenities/evcharging.jpeg"
        className={styles.image}
        alt="eletric car charging"
      />
    ),
  },
];

function Amenities() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [cardLeftPos, setCardLeftPos] = useState(0);

  const calculateLeft = (index = selectedTab) => {
    if (index === 0) {
      return setCardLeftPos(window.innerWidth < 1066 ? 0 : 113);
    }
    const width =
      window.innerWidth < 1214 ? window.innerWidth : window.innerWidth;
    const card = document.getElementsByClassName(styles.card)[0];
    const cardWidth = card.scrollWidth;
    const left = (width - cardWidth) / 2 - (cardWidth + 45) * index;

    setCardLeftPos(left);
  };

  const handleSelection = e => {
    const index = parseInt(e.currentTarget.dataset.index);

    setSelectedTab(index);
    calculateLeft(index);
  };

  const goToTab = tabIndex => {
    const newIndex =
      tabIndex < 0 ? tabList.length - 1 : tabIndex % tabList.length;

    setSelectedTab(newIndex);
    calculateLeft(newIndex);
  };

  useEffect(() => {
    calculateLeft();
  }, []);

  return (
    <div className={classNames(styles.relativeWrapper, styles.dark)} style={{ padding: 0 }}>
      <div className={styles.container} id="amenities">
        <div className={styles.textContainer}>
          <h3>Amenities at our community properties</h3>
          <h2>
            Live insouciantly
          </h2>
          <p>Indulge and imbibe, refresh and rejuvenate, gratify and aggrandize your living.
          </p>
        </div>

        <div className={styles.carouselContainer}>
          <div className={styles.tabs}>
            {tabList.map((tabTitle, index) => (
              <div
                key={index}
                onClick={handleSelection}
                className={classNames(styles.tab, {
                  [styles.selected]: index === selectedTab,
                })}
                data-index={index}
              >
                <div className={styles.backgroundImage} />
                <div className={styles.foregroundImage} />
                <span>{tabTitle}</span>
              </div>
            ))}
          </div>

          <div className={styles.mobileTabs}>
            <p>AMENITIES</p>
            <div className={styles.decorLine} />
          </div>

          <div className={styles.carousel}>
            {
              selectedTab > 0 &&
              <BackButton
                page={selectedTab}
                pageCount={tabList.length}
                onClick={() => goToTab(selectedTab - 1)}
                className={classNames(styles.arrow, styles.backButton)}
                small
              />
            }
            {
              selectedTab < tabList.length - 1 &&
              <NextButton
                page={selectedTab}
                pageCount={tabList.length}
                onClick={() => goToTab(selectedTab + 1)}
                className={classNames(styles.arrow, styles.nextButton)}
                small
              />
            }
            <div
              className={styles.cardsWrapper}
              style={{ left: `${cardLeftPos}px` }}
            >
              {carouselContent.map((data, index) => (
                <div key={index} className={styles.card}>
                  {data.image}
                  <div className={styles.info}>
                    <div className={styles.content}>
                      <span>{data.title}</span>
                      <div className={styles.divider} />
                      {data.content}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <StaticImage
        className={styles.decor}
        style={{ bottom: '-800px', right: '64%', width: '1126px' }}
        src="../../../images/arbol/landing-page/decor8.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ bottom: '-800px', left: '64%', width: '1126px' }}
        src="../../../images/arbol/landing-page/decor9.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      /> */}
    </div>
  );
}

export default Amenities;
