// extracted by mini-css-extract-plugin
export var relativeWrapper = "Footer-module--relativeWrapper--1G-_b";
export var decor = "Footer-module--decor--3Lumj";
export var hamburger = "Footer-module--hamburger--rwp03";
export var units = "Footer-module--units--1unjP";
export var reactCalendarMonthViewDays = "Footer-module--react-calendar__month-view__days--ncniL";
export var reactCalendarTile = "Footer-module--react-calendar__tile--BrH-j";
export var reactCalendarTileActive = "Footer-module--react-calendar__tile--active--1GWFn";
export var container = "Footer-module--container--xjlOH";
export var row = "Footer-module--row--1F18p";
export var lastRow = "Footer-module--lastRow--gdJzC";
export var wrapper = "Footer-module--wrapper--1HYs6";
export var social = "Footer-module--social--_ZBDY";
export var logo = "Footer-module--logo--fjfxt";
export var contact = "Footer-module--contact--2S7Qs";
export var copyright = "Footer-module--copyright--1-kl-";
export var programs = "Footer-module--programs--37rzo";