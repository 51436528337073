// extracted by mini-css-extract-plugin
export var relativeWrapper = "Amenities-module--relativeWrapper--27ovQ";
export var decor = "Amenities-module--decor--9MUYc";
export var hamburger = "Amenities-module--hamburger--3qlak";
export var units = "Amenities-module--units--1wThb";
export var reactCalendarMonthViewDays = "Amenities-module--react-calendar__month-view__days--1TQ2j";
export var reactCalendarTile = "Amenities-module--react-calendar__tile--2uz-K";
export var reactCalendarTileActive = "Amenities-module--react-calendar__tile--active--13YzP";
export var container = "Amenities-module--container--i2N9A";
export var textContainer = "Amenities-module--textContainer--3HRck";
export var carouselContainer = "Amenities-module--carouselContainer--3FNlk";
export var mobileTabs = "Amenities-module--mobileTabs--7UIPp";
export var decorLine = "Amenities-module--decorLine--1Ci3Y";
export var tabs = "Amenities-module--tabs--1iaZ6";
export var tab = "Amenities-module--tab--1ug6t";
export var selected = "Amenities-module--selected--3BN6E";
export var backgroundImage = "Amenities-module--backgroundImage--L9SUQ";
export var foregroundImage = "Amenities-module--foregroundImage--1YKo2";
export var carousel = "Amenities-module--carousel--2ctYi";
export var arrow = "Amenities-module--arrow--2ai0w";
export var backButton = "Amenities-module--backButton--2LHmJ";
export var nextButton = "Amenities-module--nextButton--19_5d";
export var cardsWrapper = "Amenities-module--cardsWrapper--1x2aJ";
export var card = "Amenities-module--card--24bjm";
export var image = "Amenities-module--image--1ZX-6";
export var info = "Amenities-module--info--9hG-M";
export var divider = "Amenities-module--divider--3KmiL";
export var content = "Amenities-module--content--ZlDKi";
export var microLogo = "Amenities-module--microLogo--2M7kH";