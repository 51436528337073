// extracted by mini-css-extract-plugin
export var relativeWrapper = "ArrowButton-module--relativeWrapper--1rigc";
export var decor = "ArrowButton-module--decor--1uxif";
export var hamburger = "ArrowButton-module--hamburger--18cAQ";
export var units = "ArrowButton-module--units--1aEtY";
export var reactCalendarMonthViewDays = "ArrowButton-module--react-calendar__month-view__days--1YHay";
export var reactCalendarTile = "ArrowButton-module--react-calendar__tile--3aqSg";
export var reactCalendarTileActive = "ArrowButton-module--react-calendar__tile--active--3ljl3";
export var nextButton = "ArrowButton-module--nextButton--2y1St";
export var small = "ArrowButton-module--small--1Gwg-";
export var content = "ArrowButton-module--content--3B4aq";
export var arrow = "ArrowButton-module--arrow--2SRms";
export var position = "ArrowButton-module--position--R_K2j";
export var backButton = "ArrowButton-module--backButton--3y9KU";